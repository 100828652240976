import axios from "axios";

export default {
    dataPackBuyAPi: (msisdn,pack) => {
        var data = JSON.stringify({"msisdn": msisdn,"pack":pack});
        var config = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/api/web/v1/buy_pack',
        headers: {
            'Content-Type': 'application/json'
        },
        data : data
        };
        axios(config)
        .then(res => {
            if(res.data.success){
                window.location = '/pack_success';
            } else {
                window.location = '/pack_error';
            }
        })
    }
}