import React, {Component} from 'react';
import axios from "axios";
import {Link} from "react-router-dom";
import unhappyImg from "../../img/un-happy.svg";
import click from "../../sound/click.wav";
import Modal from "react-bootstrap/Modal";
import {Replay} from "vimond-replay";
import CompoundVideoStreamer from "vimond-replay/video-streamer/compound";
import HlsjsVideoStreamer from "vimond-replay/video-streamer/hlsjs";

class Answers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAnswered: false,
            //classNames: ['', '', '', '']
            preAnswer:'',
            setQstNo:'',
            setAnsType:'',
            isAdTextShow: false,
            showAd: false,
            adList: '',
            skipCounter: 30,
            skipOut: false,
            isPaused: false,
            isMuted: true
        }

        this.checkAnswer = this.checkAnswer.bind(this);
        this.clearClasses = this.clearClasses.bind(this);
        this.handleShowAd = this.handleShowAd.bind(this);
        this.getImpr = this.getImpr.bind(this);
        this.getAd = this.getAd.bind(this);
        this.handleAdSkip = this.handleAdSkip.bind(this);
        this.startSkipTimer = this.startSkipTimer.bind(this);
        this.skipTick = this.skipTick.bind(this);
        this.handleStreamStateChange = this.handleStreamStateChange.bind(this);

    }

    checkAnswer(e) {

        //ad
            this.getAd();
        //end_ad

        this.setState({
            isMuted: true
        })

        new Audio(click).play();
        let { isAnswered } = this.props;
        //alert(this.props.noQst);
        if(!isAnswered) {
            let elem = e.currentTarget;
            let { correct,difficulty, increaseScore,decreaseScore,countRightAnswer,descreaseRightAnswer } = this.props;
            let answer = Number(elem.dataset.id);
            //alert(answer);
            this.setState({
                preAnswer:answer
            })

            let updatedClassNames = this.props.classNames;
            updatedClassNames[this.state.preAnswer-1] = '';
            if(answer == correct){
                updatedClassNames[answer - 1] = 'right';
                if(this.props.noQst == this.state.setQstNo && this.state.setAnsType == 'right'){

                } else {
                    this.setState({
                        setAnsType:'right',
                        setQstNo:this.props.noQst
                    })
                    switch (difficulty) {
                        case 'l1':
                            this.props.increaseScore(5);
                            this.props.countRightAnswer();
                            break;
                        case 'l2':
                            this.props.increaseScore(10);
                            this.props.countRightAnswer();
                            break;
                        case 'l3':
                            this.props.increaseScore(50);
                            this.props.countRightAnswer();
                            break;
                        default:
                            this.props.increaseScore(5);
                            this.props.countRightAnswer();
                    }
                }

                this.props.showButton(true,true);
                this.setState({
                    isAdTextShow: false
                })
            }
            else {

                this.props.showButton(true,false);
                this.setState({
                    isAdTextShow: true
                })

                updatedClassNames[answer - 1] = 'wrong';

                if(this.state.setAnsType == 'wrong'){

                } else {
                    this.setState({
                        setAnsType: 'wrong',
                        setQstNo:this.props.noQst
                    })
                    if(this.props.noQst == this.state.setQstNo) {
                        switch (difficulty) {
                            case 'l1':
                                this.props.decreaseScore(5);
                                this.props.descreaseRightAnswer();
                                break;
                            case 'l2':
                                this.props.decreaseScore(10);
                                this.props.descreaseRightAnswer();
                                break;
                            case 'l3':
                                this.props.decreaseScore(50);
                                this.props.descreaseRightAnswer();
                                break;
                            default:
                                this.props.decreaseScore(5);
                                this.props.descreaseRightAnswer();
                        }
                    }
                }
            }

            // this.setState({
            //     classNames: updatedClassNames,
            //
            // })


            var myTime = setTimeout(() => {
                //this.clearClasses();
                //console.log("IN SET Timeout")
            }, 5000);
        }
    }
    handleShowAd(id) {


        this.setState({
            showAd: true,
            skipCounter: 30,
            skipOut: false
        })
        this.props.handleDescreaseLifeTime();
        this.getImpr(id);
        this.startSkipTimer();
    }
    getAd() {
        var config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/quiz/ad/v1/get_All_Ad',
            headers: {
                'Content-Type': 'application/json'
            }
        };

        axios(config)
            .then(response=> {
                console.log(JSON.stringify(response.data.data));
                this.setState({
                    adList: response.data.data
                })
                //alert(this.state.adList.id)
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    getImpr(id) {
        console.log("Impression"+id);
        var data = JSON.stringify({"id":`${id}`,"type":"impr"});

        var config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/quiz/ad/v1/store_impression',
            headers: {
                'Content-Type': 'application/json'
            },
            data : data
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    handleAdSkip(id) {
        this.setState({
            showAd: false
        })
        this.props.showButton(true);
        //click_count
        var data = JSON.stringify({"id":`${id}`,"type":"skip"});

        console.log("Skip"+ id);

        var config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/quiz/ad/v1/store_impression',
            headers: {
                'Content-Type': 'application/json'
            },
            data : data
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });
        //end_click_count
    }
    skipTick () {
        console.log("skip");
        console.log(this.state.skipCounter);
        this.setState({
            isPaused: false,
            isMuted: false
        })
        if(this.state.skipCounter < 0){
            clearInterval(this.timer)
            this.setState({
                skipOut: true,
                isMuted: true
            })
            this.handleAdSkip();
            this.props.autonNextQuestion();
        } else {
            this.setState({skipCounter: (this.state.skipCounter - 1)})
        }

    }
    startSkipTimer () {
        clearInterval(this.timer)
        this.timer = setInterval(
            this.skipTick.bind(this)
            , 1000)
    }

    handleStreamStateChange = stateProperties => {
        console.log(this.state.isMuted);
        if (stateProperties) {
            // if ('position' in stateProperties) {
            //     console.log(
            //         'Stream observation example: Playback position is ' +
            //         stateProperties.position.toFixed(1),
            //     )
            // }
            // if ('volume' in stateProperties) {
            //     console.log(
            //         'Stream observation example: Playback position is ' +
            //         Math.round(stateProperties.volume * 100),
            //     )
            // }
            // if (stateProperties.isPaused) {
            //     console.log('Stream observation example: The playback was paused.')
            // }
            // if (stateProperties.isPaused === false) {
            //     console.log('Stream observation example: The playback was resumed.')
            // }
            // if (stateProperties.playState === 'inactive') {
            //     console.log('Stream observation example: The playback has ended.')
            // }
            this.setState({
                isPaused: false,
                isMuted: false
            })
        }
    }


    playbackActionsReady = ({ play, setPosition, setProperties }) => {
        console.log("play"+play)
        console.log("setPosition"+setPosition)
        console.log("setProperties"+setProperties)
        let playVideo = play
        let setPlaybackPosition = setPosition
        let setPlaybackProperties = true
    }

    clearClasses(){
        this.setState({
            classNames: ['', '', '', '']
        })

    }
    render() {
        const lang = localStorage.getItem('lang');
        let { answers } = this.props;
        let { classNames } = this.props;

        let transition = {
            transitionName: "example",
            transitionEnterTimeout: 500,
            transitionLeaveTimeout: 300
        }

        return (
            <div>

                {/*restart model*/}
                <Modal
                    show={this.state.showAd}
                    onHide={this.state.showAd}
                    size="md"
                    fullscreen="true"
                    animation="true"
                    className={"model-ad"}
                >
                    <div className="close-btn" style={{fontSize:'22px'}}>
                        {this.state.skipOut ?
                            <Link className="" style={{display: 'flex', justifyContent: 'flex-end'}} onClick={(e)=>(this.handleAdSkip(this.state.adList.id))} >
                                <i className="fa fa-times-circle-o" aria-hidden="true"></i>
                            </Link>
                            :
                            <span style={{color:"#dc1c2c;"}}>{this.state.skipCounter == -1 ? 0 : this.state.skipCounter}</span>
                        }
                    </div>
                    <Modal.Body style={{backgroundColor: 'black'}}>

                        {/*<Replay*/}
                        {/*    options={{*/}
                        {/*        controls: {*/}
                        {/*            includeControls: [*/}
                        {/*                'playPauseButton',*/}
                        {/*                // 'timeline',*/}
                        {/*                // 'timeDisplay',*/}
                        {/*                // 'volume',*/}
                        {/*                'fullscreenButton',*/}
                        {/*            ],*/}
                        {/*        }*/}
                        {/*    }}*/}
                        {/*    source={this.state.adList.ad_image_or_video}/>*/}

                        <Replay
                                options={{
                                    controls: {
                                        includeControls: [
                                            //'playPauseButton',
                                            // 'timeline',
                                            // 'timeDisplay',
                                             //'volume',
                                            //'fullscreenButton',
                                        ],
                                    }
                                }}
                            source={this.state.adList.ad_image_or_video}
                            initialPlaybackProps={{ isPaused: false,isMuted: this.state.isMuted }}
                            onStreamStateChange={this.handleStreamStateChange}
                            onPlaybackActionsReady={this.playbackActionsReady}
                        >
                            <HlsjsVideoStreamer/>
                        </Replay>

                        {/*<video width="400" height="240" autoPlay={true} muted>*/}
                        {/*    <source src={this.state.adList.ad_image_or_video} type="video/mp4"/>*/}
                        {/*        <source src={this.state.adList.ad_image_or_video} type="video/ogg"/>*/}
                        {/*            Your browser does not support the video tag.*/}
                        {/*</video>*/}

                    </Modal.Body>
                </Modal>
                {/*end_restart_model*/}

                { !this.props.timeOut ?
                    <div id="answers">
                        <ul>
                            <li onClick={this.checkAnswer}
                                className={classNames[0]} data-id="1">
                                {/*<span><input type="radio" className="duronto_radio"/></span>*/}
                                <p>{answers[0]}</p></li>

                            <li onClick={this.checkAnswer}
                                className={classNames[1]} data-id="2">
                                {/*<span><input type="radio" className="duronto_radio"/></span>*/}
                                <p>{answers[1]}</p></li>

                            <li onClick={this.checkAnswer}
                                className={classNames[2]} data-id="3">
                                {/*<span><input type="radio" className="duronto_radio"/></span>*/}
                                <p>{answers[2]}</p></li>

                            <li onClick={this.checkAnswer}
                                className={classNames[3]} data-id="4">
                                {/*<span><input type="radio" className="duronto_radio"/></span>*/}
                                <p>{answers[3]}</p></li>
                        </ul>
                    </div>:

                        <div className="timeOutAndAd">
                            <div className="adText">
                                {/*<p>{this.state.adList.id}</p>*/}
                                {this.props.showButtonValue ? <span></span>:
                                    <div>
                                    {this.props.lifeTime > 0 ?
                                        <h5>
                                            {lang == 'english' ? 'Watch' : 'খেলা চালিয়ে যেতে'}
                                            <Link onClick={(e)=>(this.handleShowAd(this.state.adList.id))}>
                                                {lang == 'english' ? ' Ad ' : ' বিজ্ঞাপন'}
                                            </Link>
                                            {lang == 'english' ? 'to continue' : ' দেখুন'}
                                        </h5> :
                                        <h5>{lang == 'english' ? 'You have no remaining life time.' : 'আর কোন লাইফ অবশিষ্ট নেই'} <br/>
                                        <Link to="/">
                                        <i className="fa fa-home"></i>
                                            {lang == 'english' ? 'Go Home' : 'হোম এ ফিরে যান।'}</Link>
                                        </h5>
                                    }
                                    </div>
                                }
                            </div>
                            <div className="">
                               <img src={unhappyImg} className="img-fluid" alt="Logo"/>
                               <div className="timeOutText">
                                   {lang == 'english' ? 'Wrong Answer' : 'ভুল উত্তর'}
                                   {/*{this.state.isAdTextShow ? 'ভুল উত্তর' : 'সময় শেষ'}*/}
                               </div>
                            </div>
                        </div>
                }
            </div>
        );
    }
}

export default Answers