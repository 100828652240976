import React, { Component } from 'react';
import OtpInput from "react-otp-input";
import axios from 'axios';

import footerImg from '../img/powered-by.png';
import logo from '../img/app_icon.png';

import '../css/header.css';
import '../css/footer.css';
import '../css/otp.css';
import DatapackBuy from "../common/DatapackBuy";
import otp from "../common/otp";

class DataPackOTPVerify extends Component {

    constructor(props) {
        super(props);
        this.state = {
            otp: '',
            otp_msg: ''
        }

        this.handleChange = this.handleChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.resendOTP = this.resendOTP.bind(this)
    }

    handleChange(event) {

        var newIteam = event.target.name;
        var newValue = event.target.value;
        this.setState({
            [newIteam] :newValue
        })

    }

    onSubmit(event) {
        event.preventDefault()
        const msisdn = localStorage.getItem('msisdn');
        const pack = localStorage.getItem('pack');

        var data = JSON.stringify({"msisdn":msisdn,"secret_key":this.state.otp});
        var config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/otp/verification',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            },
            data : data
        };

        axios(config)
        .then(res => {
            if(res.data.success){
                const datapack_buy = DatapackBuy.dataPackBuyAPi(msisdn,pack);
                // this.setState({
                //     otp_msg: 'success',
                // });
            } else {
                this.setState({
                    otp_msg: 'Invalid OTP Code',
                });
            }
        })
    }

    resendOTP() {
        const msisdn = localStorage.getItem('msisdn');
        const all_otp = otp.getOTP(msisdn);
    }

    render() {
        const msisdn = localStorage.getItem('msisdn');
        const pack = localStorage.getItem('pack');
        return (
            <div>
                {/* =================================log============================= */}
                <div className="otpheader">
                    <img src={logo} className="img-fluid" alt="Logo"/>
                </div>
                {/* =================================end_logo========================= */}
                <div className="col-lg-12 text-center pt-5">
                    <h3>OTP Verification</h3>
                    <p>OTP Sent to: <b>{msisdn}</b><br/>
                        Selected Pack: <b>{pack}</b></p>
                </div>
                {/*form*/}
                <form className="p-2" onSubmit={this.onSubmit}>
                    <div className="col-lg-12 text-center">
                        <span className="text-danger text-">{this.state.otp_msg}</span>
                        {/*<input id="partitioned" type="text" maxLength="5"/>*/}
                        <input type="text" name="otp" value={this.state.otp} className="form-control" onChange={this.handleChange} placeholder="Enter OTP..." maxLength="6" autoComplete="true"  required/>
                        <p>Didn't receive the OTP? <a href="" onClick={this.resendOTP}>RESEND OTP</a></p>
                        <button className="btn btn-block btn-md btn-success"><b>Verify OTP</b></button>
                    </div>
                </form>
                {/*form*/}
                {/*===================================powered_buy=========================*/}
                <div className="footerImg text-center">
                    <img src={footerImg} alt="robi" />
                </div>
                {/*===================================end_powered_buy======================*/}
            </div>
        );
    }
}

export default DataPackOTPVerify;