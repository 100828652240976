import React, {Component} from 'react';
import quiz_header_img from "../../../img/header.png";
import axios from "axios";

class LabelTwo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lifeTime2Data: ''
        }
    }

    componentDidMount() {
        // life_time
        var config2 = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/quiz/ad/v1/get_life_time',
            headers: {
                'Content-Type': 'application/json'
            }
        };

        axios(config2)
            .then(response => {
                this.setState({
                    lifeTime2Data: response.data.data.round_two_lifetime,
                })
            })
            .catch(function (error) {
                console.log(error);
            });
        //end_life_time
    }
    render() {
        const lang = localStorage.getItem('lang');
        return (
            <div>
                {/* =================================log============================= */}
                <div>
                    <div className="quiz_header">
                        <img src={quiz_header_img} className="img-fluid" alt="Logo"/>
                    </div>
                </div>
                {/* =================================end_logo========================= */}

                <div className="container">

                    <div className="row">
                        <div className="col-lg-12 col-md-10">
                            <center class="labelOneTop">
                                <h3>
                                    {lang == 'english' ? 'ROUND 2' : '২য় রাউন্ড'}
                                </h3>
                                <h5>{lang == 'english' ? 'Difficulty Level: Medium' : 'ডিফিকাল্টি লেভেল: একটু কঠিন'}</h5>
                            </center>

                            <div className="labelOne">
                                <span className="welcome">
                                    {lang == 'english' ? 'Welcome to ROUND 2!' : '২য় রাউন্ডে আপনাকে স্বাগতম!'}
                                </span>
                                <br/><br/>
                                {lang == 'english' ?
                                    <span className="labelOneltext">
                                    ROUND 2 is a real deal. You will face 3 multiple type questions. Which are a bit difficult, that’s why you will Get 10 points for each correct answer.
                                <br/><br/>
                                    Are you ready?
                                <br/><br/>
                                      <p className="lifeTImeLabel">
                                          {/*Life time :*/}
                                          <i className="fa fa-heart"></i> {this.state.lifeTime2Data}
                                      </p>
                                </span> :
                                    <span className="labelOneltext">
                                    এই রাউন্ডটি আগেরটির চেয়ে একটু কঠিন। এতে প্রশ্ন আছে ৩ টি। আর প্রতিটি সঠিক উত্তরের জন্য রয়েছে ১০ পয়েন্ট।
                                <br/><br/>
                                    চলুন শুরু করা যাক।
                                <br/><br/>
                                      <p className="lifeTImeLabel">
                                          {/*Life time :*/}
                                          <i className="fa fa-heart"></i> {this.state.lifeTime2Data}
                                      </p>
                                </span>
                                }

                                <br/><br/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default LabelTwo;