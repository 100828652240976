import React, {Component} from 'react';
import logo from "../img/logo.png";

class Weather extends Component {
    render() {
        return (
            <div>


                {/* =================================log============================= */}
                <div className="dLogo">
                    <img src={logo} className="img-fluid" alt="Logo"/>
                </div>
                {/* =================================end_logo========================= */}

                {/* ==================================weather========================= */}
                <div className="container">
                    <b>Today Weather</b>
                    <div className="m-2">
                        <h2>Dhaka</h2>
                        <a target="_blank" href="https://www.booked.net/weather/dhaka-2125">
                            <img src="https://w.bookcdn.com/weather/picture/1_2125_1_1_137AE9_320_ffffff_333333_08488D_1_ffffff_333333_0_6.png?scode=2&domid=w209&anc_id=34565" alt="booked.net" />
                        </a>
                    </div>

                    <div className="m-2">
                        <h2>Chittagong</h2>
                        <a target="_blank" href="https://www.booked.net/weather/chittagong-42433">
                            <img src="https://w.bookcdn.com/weather/picture/1_42433_1_1_137AE9_320_ffffff_333333_08488D_1_ffffff_333333_0_6.png?scode=2&domid=w209&anc_id=34565" alt="booked.net" />
                        </a>
                    </div>

                    <div className="m-2">
                        <h2>Sylhet</h2>
                        <a target="_blank" href="https://www.booked.net/weather/sylhet-58041">
                            <img src="https://w.bookcdn.com/weather/picture/1_58041_1_1_137AE9_320_ffffff_333333_08488D_1_ffffff_333333_0_6.png?scode=2&domid=w209&anc_id=8667" alt="booked.net" />
                        </a>
                    </div>

                    <div className="m-2">
                        <h2>Khulna</h2>
                        <a target="_blank" href="https://www.booked.net/weather/khulna-66494">
                            <img src="https://w.bookcdn.com/weather/picture/1_66494_1_1_137AE9_320_ffffff_333333_08488D_1_ffffff_333333_0_6.png?scode=2&domid=w209&anc_id=8667" alt="booked.net" />
                        </a>
                    </div>

                    <div className="m-2">
                        <h2>Rangpur</h2>
                        <a target="_blank" href="https://www.booked.net/weather/rangpur-387294">
                            <img src="https://w.bookcdn.com/weather/picture/1_387294_1_1_137AE9_320_ffffff_333333_08488D_1_ffffff_333333_0_6.png?scode=2&domid=w209&anc_id=8667" alt="booked.net" />
                        </a>
                    </div>

                    <div className="m-2">
                        <h2>Barisal</h2>
                        <a target="_blank" href="https://www.booked.net/weather/barisal-476419">
                            <img src="https://w.bookcdn.com/weather/picture/1_476419_1_1_137AE9_320_ffffff_333333_08488D_1_ffffff_333333_0_6.png?scode=2&domid=w209&anc_id=8667" alt="booked.net" />
                        </a>
                    </div>

                    <div className="m-2">
                        <h2>Rajshahi</h2>
                        <a target="_blank" href="https://www.booked.net/weather/rajshahi-101913">
                            <img src="https://w.bookcdn.com/weather/picture/1_101913_1_1_137AE9_320_ffffff_333333_08488D_1_ffffff_333333_0_6.png?scode=2&domid=w209&anc_id=8667" alt="booked.net" />
                        </a>
                    </div>

                </div>
                {/* ==================================end_weather========================= */}

            </div>
        );
    }
}

export default Weather;