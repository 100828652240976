import React, { Component } from 'react';

//import data from "./Data";
import Answers from "./Answers";
import '../../css/quiz.css';
import './style.css';
import quiz_header_img from "../../img/header.png";
import nextclick from '../../sound/slider.wav';
import footer_img from "../../img/footer.png"
import winnerUpdate from "./common/winnerUpdate";
import Popup from "./Popup";
import {Link, Redirect} from "react-router-dom";
import Winner from "./common/Winner";
import Fail from "./Fail";
import footerImg from "../../img/powered-by.png"
import Modal from "react-bootstrap/Modal";
import LabelOne from "./common/LabelOne";
import LabelTwo from "./common/LabelTwo";
import LabelThree from "./common/LabelThree";

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            count: 0,
            total: this.props.data.length,
            showButton: false,
            questionAnswered: false,
            score: 0,
            rightAnswer: 0,
            displayPopup: 'none',
            isWinner: '2',
            classNames: ['', '', '', ''],
            timeInc: 20,
            timeOut: false,
            labelName:'',
            labelModelShow: false,
            whichLabel: '1',
            labelModelMsg: '',
            roundNumber: 0,
            totalRoundQuiz: 10,
            presentTime: '',
            expiryTime: ''
        }
        this.nextQuestion = this.nextQuestion.bind(this);
        this.handleShowButton = this.handleShowButton.bind(this);
        this.handleStartQuiz = this.handleStartQuiz.bind(this);
        this.handleIncreaseScore = this.handleIncreaseScore.bind(this);
        this.handleDecreaseScore = this.handleDecreaseScore.bind(this);
        this.handlecountRightAnswer = this.handlecountRightAnswer.bind(this);
        this.handleDescreaseRightAnswer = this.handleDescreaseRightAnswer.bind(this);
        this.clearClasses = this.clearClasses.bind(this);
        this.startTimer = this.startTimer.bind(this);
        this.handleClose = this.handleClose.bind(this);

    }

    componentWillMount() {
        let { count } = this.state;
        this.insertData(count);
        this.startTimer();
    }

    insertData(count) {
        const data = this.props.data;
        this.setState({
            question: data[count].question,
            answers: [
                data[count].answers[0],
                data[count].answers[1],
                data[count].answers[2],
                data[count].answers[3]
            ],
            correct: data[count].correct,
            difficulty: data[count].difficulty,
            count: this.state.count + 1
        });
        if(count == '0'){
            //alert("Label 1")
            this.setState({
                labelModelShow: true,
                whichLabel: '1',
                timeInc: 180
            })
            this.startTimer();
        } else if (count == '5'){
            this.setState({
                labelModelShow: true,
                whichLabel: '2',
                timeInc: 180
            })
            this.startTimer();
        } else if(count == '8') {
            this.setState({
                labelModelShow: true,
                whichLabel: '3',
                timeInc: 180
            })
            this.startTimer();
        }
        if(data[count].difficulty == 'l1'){
            this.setState({
                labelName: 'Round 1',
                roundNumber: 0,
                totalRoundQuiz: 5
            })
        }
        else if(data[count].difficulty == 'l2'){
            this.setState({
                labelName: 'Round 2',
                roundNumber: 5,
                totalRoundQuiz: 3
            })
        }
        else if(data[count].difficulty == 'l3'){
            this.setState({
                labelName: 'Final Round',
                roundNumber: 8,
                totalRoundQuiz: 2
            })
        } else {
            this.setState({
                labelName: 'Wrong Round 1',
                roundNumber: 0
            })
        }
    }


    handleShowButton() {
        this.setState({
            showButton: true,
            questionAnswered: false
        })
    }

    nextQuestion() {
        this.clearClasses();


        this.setState({
            timeInc: 20,
            timeOut: false
        })
        this.startTimer();

        new Audio(nextclick).play();

        let { count, total} = this.state;
        const get_data = localStorage.getItem('user_data');
        const userdata = JSON.parse(get_data);

        if(count == total){
            if(total == this.state.rightAnswer){
                //alert("You are win");
                //alert(userdata.id);
                const winner = winnerUpdate.updateWinner(userdata.id);
                //alert(winner);
                //window.location = '/quiz_winner';
                this.setState({
                    isWinner: '1'
                })
            } else {
                //alert(this.state.score);
                const winner = winnerUpdate.updateUserPoint(userdata.formatted_msisdn,this.state.score);
                //alert("Try again");
                //window.location = '/quiz_fail';
                this.setState({
                    isWinner: '0'
                })
            }
            // const result = {
            //     'score':this.state.score,
            //     'rightAnswer':this.state.rightAnswer
            // };
            //alert(userdata.id);
            //alert("Complete Quiz"+ JSON.stringify(result));
            // this.setState({
            //     displayPopup: 'flex'
            // });
        } else {
            this.insertData(count);
            this.setState({
                showButton: false,
                questionAnswered: false
            });
        }
    }

    handleStartQuiz() {
        this.setState({
            displayPopup: 'none',
            count: 1
        });
    }

    handleIncreaseScore(inc) {
        this.setState({
            score: this.state.score + inc
        });
    }

    handleDecreaseScore(inc) {
        this.setState({
            score: this.state.score - inc
        });
    }

    handlecountRightAnswer(inc) {
        this.setState({
            rightAnswer: this.state.rightAnswer + 1
        });
    }
    handleDescreaseRightAnswer(inc) {
        this.setState({
            rightAnswer: this.state.rightAnswer - 1
        });
    }

    tick () {
        console.log("tick");
        console.log(this.state.timeInc);
        if(new Date() >= this.state.expiryTime) {
            clearInterval(this.timer)
            this.setState({
                timeOut: true
            })
        }
        if(this.state.timeInc <= 0){
            //console.log("Complet");
            clearInterval(this.timer)
            this.setState({
                timeOut: true
            })
            new Audio(nextclick).play();
        } else {
            //console.log("sd  "+Math.floor((this.state.expiryTime - this.state.presentTime)/ 1000))
            //this.setState({timeInc: (this.state.timeInc - 1)})
            this.setState({
                presentTime: new Date(),
                timeInc: Math.floor((this.state.expiryTime - new Date())/ 1000)
            })
        }

    }
    startTimer () {
        //console.log(this.state.timeInc);
        this.setState({
            expiryTime: new Date(new Date().getTime() + 20000)
        })
        clearInterval(this.timer)
        this.timer = setInterval(
            this.tick.bind(this)
            , 1000)
    }

    clearClasses(){
        this.setState({
            classNames: ['', '', '', '']
        })

    }

    handleClose(){
        new Audio(nextclick).play();
        this.setState({
            labelModelShow: false,
            timeInc: 20,
            timeOut: false
        })
        this.clearClasses();
        this.startTimer();
    }

    render() {
        const lang = localStorage.getItem('lang');
        let { count, total, question, answers, correct,difficulty, showButton, questionAnswered, displayPopup, score} = this.state;
        if (this.state.isWinner == '1') {
            //return <Redirect to={this.state.redirect} />
            return (
                <div>
                    {
                        <Winner/>
                    }
                </div>
            );
        } else if(this.state.isWinner == '0'){
            return (
                <div>
                    {
                        <Fail total={this.state.total} score={this.state.score} rightAns={this.state.rightAnswer}/>
                    }
                </div>
            );
        }

        if(this.state.labelModelShow && this.state.whichLabel == '1'){
            return (
                <div>
                    <LabelOne/>
                    <div className="labelContinue">
                            <button className="btn btn-md duronto_next_btn"
                                    onClick={this.handleClose} >
                                {lang == 'english' ? 'Start' : 'শুরু'}
                            </button>
                    </div>
                </div>
            )
        }
        else if(this.state.labelModelShow && this.state.whichLabel === '2'){
            return (
                <div>
                    <LabelTwo/>
                    <div class="labelContinue">
                        <button className="btn btn-md duronto_next_btn"
                                onClick={this.handleClose} >
                            {lang == 'english' ? 'Start' : 'শুরু'}
                        </button>
                    </div>
                </div>
            )
        }
        else if(this.state.labelModelShow && this.state.whichLabel == '3'){
            return (
                <div>
                    <LabelThree/>
                    <div className="labelContinue">
                        <button className="btn btn-md duronto_next_btn"
                                onClick={this.handleClose} >
                            {lang == 'english' ? 'Start' : 'শুরু'}
                        </button>
                    </div>
                </div>
            )
        }
        else{
            return (
                <div>
                    {/* =================================log============================= */}
                    <div>
                        <div className="quiz_header">
                            <img src={quiz_header_img} className="img-fluid" alt="Logo"/>
                            {/*{question}*/}
                        </div>
                    </div>
                    {/* =================================end_logo========================= */}

                    <div className="container">

                        <div className="row">
                            <div className="col-lg-12 col-md-10">
                                <div className="label">
                                    {this.state.labelName}
                                </div>
                                <div className="counter">
                                    {this.state.timeInc < 0 ? 0 : this.state.timeInc}
                                </div>
                                <div id="question">
                                <span className="text-left">Question {count-this.state.roundNumber}/{this.state.totalRoundQuiz}
                                {/*<span className="text-left">Question {count-this.state.roundNumber}/{total}*/}
                                </span>
                                    <p>{question}</p>
                                </div>

                                {/*<p>{this.state.score}</p>*/}
                                {/*<p>{this.state.rightAnswer}</p>*/}

                                <Answers
                                    noQst={count}
                                    answers={answers}
                                    classNames={this.state.classNames}
                                    correct={correct}
                                    difficulty={difficulty}
                                    showButton={this.handleShowButton}
                                    isAnswered={questionAnswered}
                                    increaseScore={this.handleIncreaseScore}
                                    decreaseScore={this.handleDecreaseScore}
                                    countRightAnswer={this.handlecountRightAnswer}
                                    descreaseRightAnswer={this.handleDescreaseRightAnswer}
                                    timeOut={this.state.timeOut}
                                />


                                <div id="submit">
                                    {true ?
                                        <button className="btn btn-md duronto_next_btn"
                                                onClick={this.nextQuestion} >
                                            {count == total ? 'Submit' : 'Next question'}
                                        </button> : <span></span>}
                                </div>

                                {/*<div className="footer_quiz">*/}
                                {/*    <img src={footer_img} alt="footer" />*/}
                                {/*</div>*/}
                            </div>
                        </div>

                    </div>



                </div>
            )
        }
    }
}
export default Main;