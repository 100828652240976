import React, {Component} from 'react';
import otplogo from "../../img/app_icon.png";
import footerImg from "../../img/powered-by.png";
import axios from "axios";
import otp from "../../common/otp";
import Data from "../quiz4/Data";
import {Link} from "react-router-dom";
import Loader from "react-loader-spinner";
import nextclick from "../../sound/slider.wav";
import typeclick from "../../sound/type.mp3";

class QuizOtpVerify extends Component {

    constructor(props) {
        super(props);
        this.state = {
            otp: '',
            otp_msg: '',
            otp_msgbn: '',
            isQstPage2:''
        }

        this.handleChange = this.handleChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.resendOTP = this.resendOTP.bind(this)
    }

    handleChange(event) {

        var newIteam = event.target.name;
        var newValue = event.target.value;
        this.setState({
            [newIteam] :newValue
        })
        new Audio(typeclick).play();
    }

    onSubmit(event) {
        event.preventDefault()
        new Audio(nextclick).play();
        const get_data = localStorage.getItem('user_data_otp');
        const userdata = JSON.parse(get_data);
        //console.log(userdata);
        //alert(userdata.formatted_msisdn);


        var data = JSON.stringify({"msisdn":userdata.formatted_msisdn,"secret_key":this.state.otp});
        var config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/otp/verification',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            },
            data : data
        };

        axios(config)
            .then(res => {
                if(res.data.success){
                    //window.location = '/quiz_question';
                    localStorage.setItem('user_data', JSON.stringify(userdata));
                    this.setState({
                        isQstPage2:'qq'
                    })
                } else {
                    this.setState({
                        otp_msg: 'Invalid OTP Code',
                        otp_msgbn: 'ভুল ওটিপি কোড',
                    });
                }
            })


    }

    resendOTP() {
        const get_data = localStorage.getItem('user_data_otp');
        const userdata = JSON.parse(get_data);
        const all_otp = otp.getOTP(userdata.formatted_msisdn);
        this.setState({
            otp_msg: 'OTP Sent Successfully',
            otp_msgbn: 'পুনরায় OTP পাঠানো হয়েছে'
        });
    }

    render() {
        const lang = localStorage.getItem('lang');
        const get_data = localStorage.getItem('user_data_otp');
        const userdata = JSON.parse(get_data);
        {/*go_qst_page*/}
        if (this.state.isQstPage2 == 'qq') {
            return (
                <div>
                    {
                        <Data fromHomePage={"true"}/>
                    }
                </div>
            );
        }
        {/*end_go_qst_page*/}
        return (
            <div>
                {/* =================================log============================= */}
                <div className="otpheader">
                    <img src={otplogo} className="img-fluid" alt="Logo"/>
                </div>
                {/* =================================end_logo========================= */}
                <div className="col-lg-12 text-center pt-5">
                    <h3>{lang == 'english' ? 'OTP Verification' : 'ওটিপি যাচাইকরণ'}</h3>
                    <p>{lang == 'english' ? 'OTP Sent to:' : 'ওটিপি প্রেরণ:'}  <b>{userdata.formatted_msisdn}</b><br/></p>
                </div>
                {/*form*/}
                <form className="p-2" onSubmit={this.onSubmit}>
                    <div className="col-lg-12 text-center">
                        <span className="text-danger text-">{lang == 'english' ? this.state.otp_msg : this.state.otp_msgbn}</span>
                        {/*<input id="partitioned" type="text" maxLength="5"/>*/}
                        <input type="text" name="otp" value={this.state.otp} className="form-control" onChange={this.handleChange} placeholder={lang == 'english' ? 'Enter OTP...' : 'ওটিপি প্রবেশ করান...'} maxLength="6" autoComplete="true"  required/>
                        <p className="pt-3">{lang == 'english' ? 'Didn\'t receive the OTP?' : 'ওটিপি পাননি?'} <Link href="" onClick={this.resendOTP}>{lang == 'english' ? 'RESEND OTP' : 'আবার ওটিপি প্রেরণ করুন'}</Link></p>
                        <button className="btn btn-block btn-md duronto_btn2"><b>{lang == 'english' ? 'Verify OTP' : 'ওটিপি যাচাই করুন'}</b></button>
                    </div>
                </form>
                {/*form*/}
                {/*===================================powered_buy=========================*/}
                {/*<div className="footerImg text-center">*/}
                {/*    <img src={footerImg} alt="robi" />*/}
                {/*</div>*/}
                {/*===================================end_powered_buy======================*/}
            </div>
        );
    }
}

export default QuizOtpVerify;