import React, {Component} from 'react';
import logo from "../img/logo.png";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import axios from "axios";
import InfinitScroll from "react-infinite-scroll-component";

class Youtube extends Component {
    constructor(props) {
        super(props);
        this.state = {
            all_youtube: [],
            pageSize: 5,
            page: 1,
            isLoading: false
        }

        this.fetchNextUsers = this.fetchNextUsers.bind(this);
        this.sayHello = this.sayHello.bind(this);

    }

    componentDidMount() {
        const { pageSize, page } = this.state
        var config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/get_youtube?page='+`${page}`+'&pageSize='+`${pageSize}`,
            headers: {
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            }
        };

        axios(config)
            .then(response=> {
                console.log(JSON.stringify(response.data));
                this.setState({
                    all_youtube: response.data.data,
                    isLoading: true
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    sayHello(e) {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser");

        var raw = JSON.stringify({"content_id":`${e}`,"src":"duronto","clickref":"23984yyljliefewc"});

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_API_URL+"/api/app/v2/hit_count", requestOptions)
            .then(
                response => response.text()

            )
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.all_youtube.length !== this.state.all_youtube.length) {
            this.setState({
                all_youtube: this.state.all_youtube
            })
        }
    }

    fetchNextUsers() {
        const { pageSize, page } = this.state
        this.setState({
            page: this.state.page + 1
        })

        var config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/get_youtube?page='+`${page}`+'&pageSize='+`${pageSize}`,
            headers: {
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            }
        };

        axios(config)
            .then(response=> {
                console.log(JSON.stringify(response.data));
                this.setState({
                    all_youtube: this.state.all_youtube.concat(response.data.data),
                    isLoading: true
                })
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    render() {
        return (
            <div className="container">
                {/* =================================log============================= */}
                <div className="dLogo">
                    <img src={logo} className="img-fluid" alt="Logo"/>
                </div>
                {/* =================================end_logo========================= */}

                {/*  =================================youtube_content=================  */}
                <InfinitScroll
                    dataLength = {this.state.all_youtube.length}
                    next = {this.fetchNextUsers}
                    hasMore = {true}
                    loader={<h4></h4>}
                    endMessage={
                        <p style={{ textAlign: "center" }}>
                            <b>Yay! You have seen it all</b>
                        </p>
                    }
                >
                {
                    !this.state.isLoading ?
                        <div className="loader">
                            <Loader
                                type="Circles"
                                color="#dc1c2c"
                                height={50}
                                width={100}
                            />
                        </div>
                        :
                        this.state.all_youtube.map(cat =>
                            <div className="whatsNewItem cusCol">
                                <a href={cat.link} onClick={(e)=>(this.sayHello(cat.id))} className="whatsNewItemInner">
                                    <div className="itemImg">
                                        <img src={cat.banner_image} className="img-fluid"
                                             alt="Logo"/></div>
                                    <div className="info">
                                        <div className="title">
                                            {cat.title_bn}
                                            <p><i className="fa fa-clock-o" aria-hidden="true"></i> &nbsp;
                                                {cat.content_date}</p>
                                        </div>
                                        {/*<div className="exPlore d-flex justify-content-between align-items-center">*/}
                                        {/*                      <span>*/}
                                        {/*                          <span className="linked">Explore*/}
                                        {/*                              <i className="fa fa-angle-right"></i>*/}
                                        {/*                          </span>*/}
                                        {/*                      </span>*/}
                                        {/*    <span>*/}
                                        {/*        <i className="fa fa-share-alt"></i>*/}
                                        {/*    </span>*/}
                                        {/*</div>*/}
                                    </div>
                                </a>
                            </div>
                        )
                }
                </InfinitScroll>
                {/*  =================================end_youtube_content=================  */}

            </div>
        );
    }
}

export default Youtube;