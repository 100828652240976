import React, { Component } from 'react';
import {
    Link
} from 'react-router-dom'
import axios from 'axios';
import { ListGroup,Image} from 'react-bootstrap';

import footerImg from '../img/powered-by.png';
import logo from '../img/logo.png';
import otp from "../common/otp";
import DatapackBuy from "../common/DatapackBuy";

class DataPackApp4Page extends Component {

    constructor(props) {
        super(props);
        this.state = {
            msisdn: '',
            msisdn1: '',
            is_otp: false
        }
        
        this.handlePackBuyApp4 = this.handlePackBuyApp4.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {

        var config = {
            method: 'get',
            url: 'http://fun-world.mobi/get-msisdn.php',
          };
          
          axios(config)
          .then(res => {
            if(res.data == 'No MSISDN Found!'){
                this.setState({
                    msisdn: '',
                    is_otp: true
                });
            } else {
                this.setState({
                    msisdn: res.data,
                    is_otp: false
                });
            }
          })
        
    }

    handleChange(event) {

        var newIteam = event.target.name; 
        var newValue = event.target.value;
        this.setState({
            [newIteam] :newValue
        })
    
    }

    handlePackBuyApp4(event) {
        event.preventDefault();

        if(this.state.is_otp){
            const all_otp = otp.getOTP(this.state.msisdn1);

            localStorage.setItem('msisdn', this.state.msisdn1);
            localStorage.setItem('pack', "APP_16G");
            window.location = '/otp_verify';
        }
        else
        {
            const datapack_buy = DatapackBuy.dataPackBuyAPi(this.state.msisdn,'APP_16G');
        }

    }

    render() {
        var msisdn_form;
        if(this.state.msisdn){
            msisdn_form = "";

        } else {
            msisdn_form = <input type="text" name="msisdn1" value={this.state.msisdn1} className="form-control" onChange={this.handleChange} placeholder="Enter Mobile Number..." required/>;
        }

        return (
            <div>
                {/*================================== top_bar ================================*/}
                <div className="topBar">
                    <span className='topBarInner'>
                        <Link to="/data_pack" className="">
                            <i className="fa fa-arrow-left"></i>
                            &nbsp; Purchase Confirmation
                        </Link>
                    </span>
                </div>
                {/*===================================== end_top_bar ============================*/}

                {/* =================================log============================= */}
                <div className="dLogo">
                    <img src={logo} className="img-fluid" alt="Logo"/>
                </div>
                {/* =================================end_logo========================= */}

                {/*=================================== Pack Details ==================*/}
                <ListGroup>
                    <ListGroup.Item><b className="pr-5">Pack Name:</b>              <span className="float-right">APP_16G</span></ListGroup.Item>
                    <ListGroup.Item><b className="pr-5">Volume:  </b>               <span className="float-right">11 GB <span className="badge badge-success">1 GB 4G</span></span></ListGroup.Item>
                    <ListGroup.Item><b className="pr-5">Validity: </b>              <span className="float-right">7 Days</span></ListGroup.Item>
                    <ListGroup.Item><b className="pr-5">Price:   </b>               <span className="float-right">148 Tk</span></ListGroup.Item>
                    <br></br>
                    {/* form */}
                    <form className="p-2" onSubmit={this.handlePackBuyApp4}>
                        <p>Purchasing for: {this.state.msisdn}</p>
                        <p>
                            {msisdn_form}
                        </p>
                        <button className="btn btn-block btn-md btn-success"><b>Buy</b></button>
                    </form>
                    {/* end_form */}
               </ListGroup>
               {/*==================================== end Pack Details ===============*/}

                <div className="footerImg text-center">
                    <img src={footerImg} alt="robi" />
                </div>

            </div>
        );
    }
}

export default DataPackApp4Page;