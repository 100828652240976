import React, {Component} from 'react';
import {
    Link
} from 'react-router-dom'

import '../css/header.css';
import '../css/home.css';
import '../css/footer.css';

import footerImg from '../img/powered-by.png';
import logo from '../img/logo.png';
import Modal from "react-bootstrap/Modal";
import Loader from "react-loader-spinner";

class HomePage1 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            all_contents: [],
            isLoading: false,
            showMoreNew: false
        }
        this.sayHello = this.sayHello.bind(this);
        this.handleMoreNewsClose = this.handleMoreNewsClose.bind(this);
        this.handleMoreNewsOpen = this.handleMoreNewsOpen.bind(this);
    }

    sayHello(e) {
        //alert(e);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser");

        var raw = JSON.stringify({"content_id":`${e}`,"src":"duronto","clickref":"23984yyljliefewc"});

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_API_URL+"/api/app/v2/hit_count", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }
    handleMoreNewsOpen(e) {
        //alert("working");
        this.setState({
            showMoreNew: !this.state.showMoreNew
        })
    }
    handleMoreNewsClose(e) {
        //alert("working");
        this.setState({
            showMoreNew: !this.state.showMoreNew
        })
    }
    componentDidMount() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };


        fetch(process.env.REACT_APP_API_URL+"/api/app/v2/get_content", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                this.setState({
                    all_contents: result.data,
                    isLoading: true
                })
            })
            .catch(error => console.log('error', error));

    }

    // render() {
    //     const { error, isLoaded, contacts } = this.state;

    //     const renderAuthButton = () => {
    //         if (1) {
    //           return <button>Logout</button>;
    //         } else {
    //           return <button>GLogin</button>;
    //         }
    //       }

    //     if (error) {
    //         return <div>{error.message}error</div>;
    //     } else {
    //     return (
    //         <div>
    //             {contacts.map(item => (
    //                 <li key={item.id}>
    //                 {item.id} {item.id}
    //                 </li>
    //             ))}
    //             {renderAuthButton()}
    //         </div>
    //     );
    //             }
    // }

    render() {
        return (
            <div>
                {/* =================================log============================= */}
                <div className="dLogo">
                    <img src={logo} className="img-fluid" alt="Logo"/>
                </div>
                {/* =================================end_logo========================= */}


                {/* =================================search=========================== */}
                {/* <div className="durontoSearchBox">
                <div className="container">
                      <div className="className">
                      <div className="col-12">
                          <form method="get" action="http://localhost/browser_web/public/searchResult/" target="_top">
                              <input name="cx" type="hidden" value="9e56566440dc8f673"/>
                              <input name="ie" type="hidden" value="UTF-8"/>
                              <div className="input-group mb-3 searchBox">
                                  <input type="text" className="form-control" placeholder="" name="q" data-enableHistory="true" required/>
                                  <button className="btn" name="googleSearchStringBtn" value="Submit"><i className="fa fa-search"></i>
                                  </button>
                              </div>
                          </form>
                      </div>
                      </div>
                </div>
            </div> */}
                {/* ===================================end_search======================== */}


                {
                    !this.state.isLoading ?
                        <div className="loader">
                            <Loader
                                type="Circles"
                                color="#dc1c2c"
                                height={50}
                                width={100}
                                // timeout={1000} //3 secs
                            />
                        </div>
                        :
                    this.state.all_contents.map((item, index) => {
                        // ======================speed_dials(11)==================
                        if (item.size === "11") {
                            return (
                                <div className="mbContainer">
                                    <div className="container text-center">
                                        {/* <h2>{item.category_name}</h2> */}
                                        <div className="row cusRow">

                                            {item.category.map(cat =>
                                                <div className="text-center singleItem col">
                                                    <a href={cat.link} onClick={(e)=>(this.sayHello(cat.id))}>
                                                        <div className="itemImg">
                                                            <img src={cat.banner_image} className="img-fluid"
                                                                 alt="img"/>
                                                        </div>
                                                        <div className="title">
                                                            {cat.title_bn}
                                                        </div>

                                                    </a>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </div>
                            );
                        }
                        // ======================end speed_dials(11)===============
                        // ======================news(21)===============
                        if (item.size === "21") {
                            return (


                                <div className="whatsNewWrap robiNewsWrap">
                                    <div className="container">

                                        <h2>{item.category_name_bn}</h2>
                                        <div className="">

                                            {

                                                item.category.map((cat,index) =>
                                                    index < item.row_count ?
                                                <div className="news24Item">
                                                    <a href={cat.link} onClick={(e)=>(this.sayHello(cat.id))}
                                                       className="robiNewsWrapItemInner">
                                                        <div className="d-flex align-items-center itemInnerWrap">
                                                            <div className="itemImg">
                                                                <img src={cat.banner_image} className="img-fluid"
                                                                     alt="img"/>
                                                            </div>
                                                            <div className="info">
                                                                <div className="title">
                                                                    {cat.title_bn.substring(0, 33)}...
                                                                </div>
                                                                <p>{cat.client_name}</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    {/* <div className="shareIcon">
                                                      <span>
                                                             <i className="fa fa-share-alt"></i>
                                                      </span>
                                                    </div> */}
                                                </div>: ''
                                            )

                                            }
                                            <div className="text-center">
                                                <Link href="" onClick={this.handleMoreNewsOpen}>See more</Link>
                                                <Modal show={this.state.showMoreNew} onHide={this.handleMoreNewsClose} animation={true}
                                                       size="md"
                                                       scrollable={true}
                                                       aria-labelledby="contained-modal-title-vcenter"
                                                       centered>
                                                    <Modal.Header closeButton onClick={this.handleMoreNewsClose}>
                                                        <Modal.Title></Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        {
                                                            item.category.map((cat,index) =>
                                                                index > item.row_count ?
                                                                    <div className="news24Item">
                                                                        <a href={cat.link} onClick={(e)=>(this.sayHello(cat.id))}
                                                                           className="robiNewsWrapItemInner">
                                                                            <div className="d-flex align-items-center itemInnerWrap">
                                                                                <div className="itemImg">
                                                                                    <img src={cat.banner_image} className="img-fluid"
                                                                                         alt="img"/>
                                                                                </div>
                                                                                <div className="info">
                                                                                    <div className="title">
                                                                                        {cat.title_bn.substring(0, 33)}...
                                                                                    </div>
                                                                                    <p>{cat.client_name}</p>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                        {/* <div className="shareIcon">
                                                      <span>
                                                             <i className="fa fa-share-alt"></i>
                                                      </span>
                                                    </div> */}
                                                                    </div>: ''
                                                            )
                                                        }
                                                    </Modal.Body>
                                                    <Modal.Footer></Modal.Footer>
                                                </Modal>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            );
                        }
                        // ======================end_news(21)====================


                        //=======================latest_from_robi(24)================
                        if (item.size == "24") {


                            return (

                                <div className="whatsNewWrap robiNewsWrap">
                                    <div className="container">

                                        <h2>{item.category_name_bn}</h2>
                                        <div className="robiNewsWrapItemSlide">

                                            {item.category.map(cat =>
                                                <div className="robiNewsWrapItem cusCol">
                                                    <a href={cat.link} onClick={(e)=>(this.sayHello(cat.id))} className="robiNewsWrapItemInner">
                                                        <div className="d-flex align-items-center itemInnerWrap">
                                                            <div className="itemImg">
                                                                <img src={cat.banner_image} className="img-fluid"
                                                                     alt="img"/>
                                                            </div>
                                                            <div className="info">
                                                                <div className="title">
                                                                    {cat.title_bn}
                                                                </div>
                                                                <p>{cat.description_bn}</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    {/* <div className="shareIcon">
                                                        <span>
                                                            <i className="fa fa-share-alt"></i>
                                                        </span>
                                                    </div> */}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                            );


                        }
                        //=======================end_latest_from_robi(24)============


                        // ======================whats_a_new(31)=======================
                        if (item.size === "31") {
                            return (
                                <div className="whatsNewWrap">
                                    <div className="container">
                                        <h2>{item.category_name_bn}</h2>
                                        <div className="whatsNewItemSlide">

                                            {item.category.map(cat =>

                                                <div className="whatsNewItem cusCol">
                                                    <a href={cat.link} onClick={(e)=>(this.sayHello(cat.id))} className="whatsNewItemInner">
                                                        <div className="itemImg">
                                                            <img src={cat.banner_image} className="img-fluid"
                                                                 alt="Logo"/></div>
                                                        <div className="info">
                                                            <div className="title">
                                                                {cat.title_bn}
                                                            </div>
                                                            <div className="exPlore d-flex justify-content-between align-items-center">
                                                              <span>
                                                                  <span className="linked">Explore <i
                                                                      className="fa fa-angle-right"></i> </span>
                                                              </span>
                                                                {/* <span>
                                                                  <i className="fa fa-share-alt"></i>
                                                                </span> */}
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                </div>
                            );
                        }
                        // ======================end_whats_a_new(31)====================

                        //=======================today_in_history(33)===================
                        if (item.size === "33") {
                            return (

                                <div className="history_33">
                                    <div className="container">
                                        <h2>{item.category_name_bn}</h2>
                                        <div className="history_33ItemSlide">

                                            {item.category.map(cat =>

                                                <div className="history_33Item cusCol">
                                                    <a href={cat.link} onClick={(e)=>(this.sayHello(cat.id))} className="history_33ItemInner">
                                                        <div
                                                            className="d-flex align-items-center justify-content-between history_33ItemInnerWrap">
                                                            <div className="info">
                                                                <div className="title">
                                                                    {cat.title_bn}
                                                                </div>
                                                                <p>
                                                                    {cat.description_bn.substring(0, 33)}...
                                                                </p>
                                                                <div
                                                                    className="exPlore d-flex justify-content-between align-items-center">
                                                                <span>
                                                                    <span className="linked">Explore <i
                                                                        className="fa fa-angle-right"></i>
                                                                    </span>
                                                                </span>
                                                            </div>

                                                            </div>
                                                            <div className="itemImg">
                                                                <img src={cat.banner_image} className="img-fluid"
                                                                     alt="Logo"/>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </div>

                            );
                        }
                        //=======================end_today_in_history(33)===============


                    })
                }
                {/* <div className="footerImg text-center">
                    <img src={footerImg} alt="robi" />
                </div> */}
            </div>
        );
    }
}

export default HomePage1;