import React, {Component} from 'react';
import logo from "../img/logo.png";

class SingleRadioPage extends Component {
    render() {
        return (
            <div>
                {/* =================================log============================= */}
                <div className="dLogo">
                    <img src={logo} className="img-fluid" alt="Logo"/>
                </div>
                {/* =================================end_logo========================= */}
            </div>
        );
    }
}

export default SingleRadioPage;