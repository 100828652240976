import React, {Component} from 'react';
import '../css/movie.css';
import {Replay} from "vimond-replay";
import HlsjsVideoStreamer from "vimond-replay/video-streamer/hlsjs";

class MovieDetails2 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <div>

                <Replay
                    source={this.props.play_movie.link}
                    initialPlaybackProps={{ isPaused: false }}
                >
                    <HlsjsVideoStreamer />
                </Replay>

                {/*<span className="single_movie_title">{this.props.play_movie.title}</span><br/>*/}
                {/*<span className="single_movie_views">{this.props.play_movie.content_hit_count} views <br/>*/}
                {/*    {this.props.play_movie.content_date}</span>*/}
            </div>
        );
    }
}

export default MovieDetails2;