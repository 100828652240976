import React, { Component } from 'react';
import axios from 'axios';
import {
    Tabs,Tab,ListGroup,Image
} from 'react-bootstrap'
import { Link } from "react-router-dom";

import '../css/header.css';
import '../css/home.css';
import '../css/footer.css';

class EmergencyPage extends Component {
    constructor(props){
        super(props)
        this.state = {
            isLoading : true,
            all_links : []
        }
    }

    componentDidMount() {

        var config = {
            method: 'post',
            url: 'https://apiv1.duronto.com.bd/api/web/v1/get_emergency_link',
            headers: { 
              'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            }
          };
          
          axios(config)
          .then(res => {
              console.log(res.data.data)
            this.setState({
                isLoading: false,
                all_links: res.data.data
            });
          })

    }

    handleQueryChange(query) {

        var data = JSON.stringify({"district":query});

        var config = {
            method: 'post',
            url: 'https://apiv1.duronto.com.bd/api/web/v1/search_by_location',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios(config)
          .then(res => {
              console.log(res.data.data)
            this.setState({
                isLoading: false,
                all_links: res.data.data
            });
          })

    }

    render() {
        if(this.state.isLoading){
            return <div>Loading...</div>
        } else {

            return (
                <div>
                    {/* search bar */}
                    <input
                        onChange={({ target: { value } }) => this.handleQueryChange(value)}
                    />
                    {/* end search bar */}
                    <Tabs defaultActiveKey="Ambulance" id="uncontrolled-tab-example">
                        {
                            this.state.all_links.map((cat,key) => {
                            return <Tab eventKey={cat.category} title={cat.category_bn}>
                                        {cat.link.map(item =>
                                            <p>{item.emergency_helpline_bn}   {item.phone_bn}</p>
                                        )}
                                    </Tab>
                            })
                        }
                    </Tabs>
                </div>
            );

        }
    }
}

export default EmergencyPage;