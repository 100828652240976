import React, {Component} from 'react';

class DurontoDownlode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            device: ""
        }
    }
    componentDidMount() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            //return "Windows Phone";
            this.setState({
                device: "windows"
            })
        }

        if (/android/i.test(userAgent)) {
            //return "Android";
            this.setState({
                device: "android"
            })
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            //return "iOS";
            this.setState({
                device: "iphone"
            })
        }
    }

    render() {

        if(this.state.device === "android") {
            window.location.href = "https://play.google.com/store/apps/details?id=com.duronto.lite"
        } else if(this.state.device === "iphone") {
            window.location.href= "https://apps.apple.com/us/app/duronto-browser/id1554054216"
        } else {
            window.location.href = "https://play.google.com/store/apps/details?id=com.duronto.lite"
        }


        return (
            <>
            </>
        );
    }
}

export default DurontoDownlode;