import React, {Component} from 'react';
import logo from "../img/logo.png";
import axios from "axios";
import InfinitScroll from "react-infinite-scroll-component";
import Loader from "react-loader-spinner";
import {Link} from "react-router-dom";
import AudioDetails from "./AudioDetails";

class Audio extends Component {

    constructor(props) {
        super(props);
        this.state = {
            all_audio: [],
            pageSize: 10,
            page: 1,
            audioLink:'',
            audioShow: false,
            isLoading: true
        }
        this.fetchNextUsers = this.fetchNextUsers.bind(this);
        this.playAudio = this.playAudio.bind(this);
    }

    sayHello(id,link) {

        this.playAudio(link)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser");

        var raw = JSON.stringify({"content_id":`${id}`,"src":"duronto","clickref":"23984yyljliefewc"});

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_API_URL+"/api/app/v2/hit_count", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));


    }

    playAudio(e) {
        this.setState({
            audioLink: e,
            audioShow: true
        })
    }

    componentDidMount() {

        const {page, pageSize} = this.state;

        var config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/get_audio?page='+`${page}`+'&pageSize='+`${pageSize}`,
            headers: {
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            }
        };

        axios(config)
            .then(response => {
                console.log(JSON.stringify(response.data));
                this.setState({
                    all_audio: response.data.data,
                    isLoading: false
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.all_audio.length !== this.state.all_audio.length) {
            this.setState({
                all_audio: this.state.all_audio
            })
        }
    }

    fetchNextUsers () {
        const { pageSize, page } = this.state
        this.setState({
            page: this.state.page + 1
        })

        var config2 = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/get_audio?page='+`${page}`+'&pageSize='+`${pageSize}`,
            headers: {
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            }
        };

        axios(config2)
            .then(response => {
                console.log(JSON.stringify(response.data));
                this.setState({
                    all_audio: this.state.all_audio.concat(response.data.data),
                    isLoading: false
                })
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    render() {
        return (
            <div>
                {/* =================================log============================= */}
                <div className="dLogo">
                    <img src={logo} className="img-fluid" alt="Logo"/>
                </div>
                {/* =================================end_logo========================= */}

                {/* =================================audio_player===================== */}
                <div className="whatsNewWrap robiNewsWrap">
                    <div className="container">
                        <div className="">

                        <InfinitScroll
                            dataLength = {this.state.all_audio.length}
                            next = {this.fetchNextUsers}
                            hasMore = {true}
                            loader={<h4></h4>}
                            endMessage={
                                <p style={{ textAlign: "center" }}>
                                    <b>Yay! You have seen it all</b>
                                </p>
                            }
                        >

                            {
                                this.state.isLoading ?
                                    <div className="loader">
                                        <Loader
                                            type="Circles"
                                            color="#dc1c2c"
                                            height={50}
                                            width={100}
                                            // timeout={1000} //3 secs
                                        />
                                    </div>
                                    :
                                    this.state.all_audio.map(cat =>
                                        <div className="news24Item">
                                            <Link onClick={(e)=>(this.sayHello(cat.id,cat.link))} className="movieItemInner">
                                                <div className="d-flex align-items-center itemInnerWrap">
                                                    <div className="itemImg">
                                                        <img src={cat.banner_image} className="img-fluid"
                                                             alt="img"/>
                                                    </div>
                                                    <div className="info">
                                                        <div className="title">
                                                            {cat.title_bn.substring(0, 33)}
                                                        </div>
                                                        <p>{cat.description_bn}</p>
                                                    </div>
                                                </div>
                                                <div className="shareIcon">
                                                      <span>
                                                              <i className="fa fa-heart fa-1x fa-beat"></i>
                                                      </span>
                                                </div>
                                            </Link>
                                        </div>

                                    )

                            }
                        </InfinitScroll>
                    </div>
                    </div>
                </div>
                {
                    this.state.audioShow ?
                    <AudioDetails audioLink={this.state.audioLink}/>: <p></p>
                }
                {/* =================================end_audio_player================= */}
            </div>
        );
    }
}

export default Audio;