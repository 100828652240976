import React, { Component } from 'react';
import axios from 'axios';
import { Redirect } from 'react-router';

import logo from '../img/logo.png'
class PushPage extends Component {

    constructor(props){
        super(props);
        this.state = {
            redirect: false,
            content: []
        }
    }


    componentDidMount() {

        //impression_count
        //alert(window.location.href);
        var data = JSON.stringify({"push_link":`${window.location.href}`});

        var pushconfig = {
          method: 'post',
          url: 'http://localhost:8002/api/web/v1/push_impression_details',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };

        axios(pushconfig)
        .then(res => {
            
        })
        .catch(res => {
            
        })
        //end_impression_count

        //site_redirect
        var config = {
            url: 'http://localhost:8002/api/web/v1/get_content_details/'+`${this.props.match.params.content_id}`,
          };

        axios(config)
        .then(res => {
            if(res.status == 200){
                console.log(res.data.link)
                this.setState({
                    content: res.data.link
                });
                setTimeout(() => this.setState({ redirect: true }), 5000)
            } else {
                window.location.href='/';
            }
        })
        .catch(res => {
            window.location.href='/';
        })
        //end_site_redirect
        

        
    }

    componentWillUnmount() {
        clearTimeout(this.id)
    }





    render() {
        // const { content_id } = this.props.match.params
        if (this.state.redirect) {
            //return <Redirect to="/signin" />;
            return window.location.href=this.state.content;
        } else {
            return (
                <div>
                    <div className="dLogo">
                        <img src={logo} className="img-fluid" alt="Logo"/>
                    </div>
                </div>
            ); 
        }
    }
}

export default PushPage;