import React, {Component} from 'react';
import logo from "../img/logo.png";

import axios from 'axios';
import InfinitScroll from 'react-infinite-scroll-component'
import Loader from "react-loader-spinner";

class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            all_news: [],
            pageSize: 10,
            page: 1,
            isLoading: true
        }
        this.fetchNextUsers = this.fetchNextUsers.bind(this)
        this.sayHello = this.sayHello.bind(this);
    }

    componentDidMount() {

        const { pageSize, page } = this.state
        var config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/get_news?page='+`${page}`+'&pageSize='+`${pageSize}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            }
        };

        //alert("first:  " + config.url);
        axios(config)
            .then(response => {
                console.log(JSON.stringify(response.data));
                this.setState({
                    all_news:response.data.data,
                    isLoading: false
                })
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.all_news.length !== this.state.all_news.length) {
            this.setState({
                all_news: this.state.all_news
            })
        }
    }

    sayHello(e) {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser");

        var raw = JSON.stringify({"content_id":`${e}`,"src":"duronto","clickref":"23984yyljliefewc"});

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_API_URL+"/api/app/v2/hit_count", requestOptions)
            .then(
                response => response.text()

            )
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }

    fetchNextUsers() {
        const { pageSize, page } = this.state
        this.setState({
            page: this.state.page + 1
        })
        //count:per page koita kore dekhabe
        //start:start
        var config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/get_news?page='+`${page}`+'&pageSize='+`${pageSize}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            }
        };

        //alert("update:  "+ config.url);
        axios(config)
            .then(response => {
                console.log(JSON.stringify(response.data));
                this.setState({
                    all_news: this.state.all_news.concat(response.data.data) ,
                    isLoading: false
                })
                // this.setState({
                //     all_news:response.data.data,
                //     isLoading: false
                // })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        return (
            <div>


                {/* =================================log============================= */}
                <div className="dLogo">
                    <img src={logo} className="img-fluid" alt="Logo"/>
                </div>
                {/* =================================end_logo========================= */}


                <div className="whatsNewWrap robiNewsWrap">
                    <div className="container">

                        <h2>Today News</h2>
                        <div className="">

                            <InfinitScroll
                                dataLength = {this.state.all_news.length}
                                next = {this.fetchNextUsers}
                                hasMore = {true}
                                loader={<h4></h4>}
                                endMessage={
                                    <p style={{ textAlign: "center" }}>
                                        <b>Yay! You have seen it all</b>
                                    </p>
                                }
                            >

                            {
                                this.state.isLoading ?
                                    <div className="loader">
                                        <Loader
                                            type="Circles"
                                            color="#dc1c2c"
                                            height={50}
                                            width={100}
                                            // timeout={1000} //3 secs
                                        />
                                    </div>
                                    :
                                this.state.all_news.map(cat =>
                                <div className="news24Item">
                                    <a href={cat.link} onClick={(e)=>(this.sayHello(cat.id))}
                                       className="robiNewsWrapItemInner">
                                        <div className="d-flex align-items-center itemInnerWrap">
                                            <div className="itemImg">
                                                <img src={cat.banner_image} className="img-fluid"
                                                     alt="img"/>
                                            </div>
                                            <div className="info">
                                                <div className="title">
                                                    {cat.title_bn}
                                                </div>
                                                <p>{cat.client_name}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )

                            }
                            </InfinitScroll>
                        </div>
                    </div>
                </div>


            </div>
        );
    }
}

export default News;