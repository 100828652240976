import React, {Component} from 'react';
import axios from "axios";
import { Replay } from 'vimond-replay';
import '../css/movie.css'
import HlsjsVideoStreamer from 'vimond-replay/video-streamer/hlsjs';
import InfinitScroll from "react-infinite-scroll-component";
import Loader from "react-loader-spinner";
import {Link} from "react-router-dom";

class MovieDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            movie:[],
            all_movie: [],
            pageSize: 10,
            page: 1,
            isLoading: true
        }

        this.fetchNextUsers = this.fetchNextUsers.bind(this);
        this.relatedMovies = this.relatedMovies.bind(this);
        this.playMovie = this.playMovie.bind(this);

    }
    componentDidMount() {
        this.relatedMovies()
        this.playMovie();


    }

    playMovie() {
        var data = JSON.stringify({"movie_id": this.props.match.params.movie_id});

        var config = {
            method: 'post',
            url: 'http://localhost:8002/api/web/v1/get_movie_details',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            },
            data : data
        };

        axios(config)
            .then(response=> {
                console.log(JSON.stringify(response.data));
                if(response.status == 200){
                    this.setState({
                        movie:response.data.data
                    })
                } else {
                    window.location='/'
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    relatedMovies() {
        const { pageSize, page } = this.state
        var config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/get_movie?page='+`${page}`+'&pageSize='+`${pageSize}`,
            headers: {
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            }
        };

        axios(config)
            .then(response => {
                console.log(JSON.stringify(response.data));
                this.setState({
                    all_movie: response.data.data,
                    isLoading: false
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.all_movie.length !== this.state.all_movie.length) {
            this.setState({
                all_movie: this.state.all_movie
            })
        }
    }

    fetchNextUsers() {
        const { pageSize, page } = this.state
        this.setState({
            page: this.state.page + 1
        })

        var config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/get_movie?page='+`${page}`+'&pageSize='+`${pageSize}`,
            headers: { }
        };
        axios(config)
            .then(response => {
                console.log(JSON.stringify(response.data));
                this.setState({
                    all_movie: this.state.all_movie.concat(response.data.data),
                    isLoading: false
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        return (
            <div>
                <Replay
                    source={this.state.movie.link}
                    initialPlaybackProps={{ isPaused: false }}
                >
                    <HlsjsVideoStreamer />
                </Replay>

                <span className="single_movie_title">{this.state.movie.title}</span><br/>
                <span className="single_movie_views">{this.state.movie.content_hit_count} views <br/>
                    {this.state.movie.content_date}</span><br/>
                <span className="related_videos">
                    Related Videos
                    <hr/>
                </span>


                <div className="whatsNewWrap robiNewsWrap">
                    <div className="container">

                        <h2>Movie List</h2>
                        <div className="">

                            <InfinitScroll
                                dataLength = {this.state.all_movie.length}
                                next = {this.fetchNextUsers}
                                hasMore = {true}
                                loader={<h4></h4>}
                                endMessage={
                                    <p style={{ textAlign: "center" }}>
                                        <b>Yay! You have seen it all</b>
                                    </p>
                                }
                            >

                                {
                                    this.state.isLoading ?
                                        <div className="loader">
                                            <Loader
                                                type="Circles"
                                                color="#dc1c2c"
                                                height={50}
                                                width={100}
                                                // timeout={1000} //3 secs
                                            />
                                        </div>
                                        :
                                        this.state.all_movie.map(cat =>
                                            <div className="news24Item">
                                                <Link  to={{
                                                    pathname: `/movie_details/${cat.id}/${cat.title_bn}`
                                                }}
                                                       className="robiNewsWrapItemInner">
                                                    <div className="d-flex align-items-center itemInnerWrap">
                                                        <div className="itemImg">
                                                            <img src={cat.banner_image} className="img-fluid"
                                                                 alt="img"/>
                                                        </div>
                                                        <div className="info">
                                                            <div className="title">
                                                                {cat.title_bn.substring(0, 33)}...
                                                            </div>
                                                            <p>{cat.client_name}</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        )

                                }
                            </InfinitScroll>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MovieDetails;